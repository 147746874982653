<template>
	<div class="white_back">
		<div id="printMe" class="pa-5">
			<img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/logos%2FJump%20Logo%20Official%20(2).png?alt=media&token=b05c3dc2-22d7-4f2e-8085-2e1cfbdb47b9" alt="" style="width:240px; margin:auto;">
			<h1>Jump Check-In / Check-Out</h1>
			<h2 v-if="venueInfo">{{venueInfo.title}}</h2>
			<vue-qr v-if="venueInfo" :text="`https://jumpstaffing.com/checkinout/` + venueInfo.id" :size="300" style="margin:auto;"></vue-qr>
			<p>Scan this QR code and open the link to sign in and out of your shift</p>
		</div>
		<div>
			<button class="btn ma-2 btn__primary" @click="print()">Print</button>
			<button class="btn ma-2 btn__outlined" @click="goBack">Go Back</button>
		</div>
	</div>
</template>

<style scoped type="text/css">
	.white_back {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		text-align:center;
		margin: 0 auto;
		height: 100vh;
	}
	#printMe {
		display:flex;
		margin: 0 auto;
		flex-direction: column;
     justify-content:center;
     align-items:center;
	}
	h1 {
		margin-top: 2vh;
		margin-bottom: 1vh;
	}
	h2 {
		margin-bottom: 2vh;
	}
	p {
		margin-top: 2vh;
	}
</style>
<script>
import { mapState } from 'vuex'
import router from '@/router'
import Loader from '@/components/Loader.vue'

export default {
  name: 'venueQR',
  data () {
    return {
      output: null
    }
  },
  computed: {
    ...mapState(['venueInfo'])
  },
  created () {
    this.$store.dispatch("getVenueFromId", this.$route.params.id);
  },
  methods: {
    async print () {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
    goBack() {
	    this.$store.dispatch('clearVenueState')
	    router.go(-1)
	  },
  },

}
</script>